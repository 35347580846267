import * as React from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import "../styles/index.scss";

// markup
const IndexPage = () => {

  return (
    <main id="main">
      <Header />
      <div className="wrapper">
        <p className="info">
          Liebe Freunde und Unterstützer von Winworks,<br /><br />
          Heute teilen wir mit gemischten Gefühlen mit, dass Winworks, unser Co-Working-Space in Winnenden, eine neue Richtung einschlagen wird.<br /><br /> In den vergangenen Jahren haben wir viel erlebt – von spannenden Events bis hin zu den täglichen Herausforderungen eines jungen Unternehmens. Obwohl wir eine engagierte, wenn auch kleine Gemeinschaft hatten, hat sich gezeigt, dass der Bedarf an einem Co-Working-Space in unserer Form in dieser Region begrenzt ist.
          Daher haben wir beschlossen, unsere Dienstleistungen als Co-Working-Space einzustellen und die Räume für neue, aufregende Tätigkeiten zu nutzen. Diese Entscheidung wurde getroffen, um den Gegebenheiten und Bedürfnissen unserer Umgebung besser gerecht zu werden und neue Möglichkeiten zu erforschen.<br /><br />
          Wir möchten uns bei den wertvollen Mitgliedern unserer Community herzlich bedanken. Ihre Unterstützung, Ihr Glaube an unsere Vision und Ihre Beiträge zu großartigen Momenten und Events bei Winworks waren für uns von unschätzbarem Wert und bleiben tolle Erinnerungen.
          Auch wenn der Co-Working-Space schließt, ist dies nicht das Ende unserer Reise, sondern der Beginn eines neuen Kapitels. Wir sind gespannt darauf, was die Zukunft bringt, und hoffen, dass Sie uns auch weiterhin unterstützen und begleiten werden, während wir neue Wege beschreiten.<br /><br />
          Mit herzlichem Dank und Zuversicht in die Zukunft,<br />
          <span className="bold">Ihr Winworks-Team</span>
        </p>
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage
