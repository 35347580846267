import { Link } from 'gatsby'
import React from 'react'
import "../styles/footer.scss"

const isBrowser = typeof window !== "undefined"

const Footer = () => {

  const path = isBrowser ? window.location.pathname : "";

  return (
    <footer>
      {path !== "/" && (path === "/impressum" || path === "/datenschutz") && <Link to="/">Startseite</Link>}
      {path !== "/impressum" && <Link to="/impressum">Impressum</Link>}
      {path !== "/datenschutz" && <Link to="/datenschutz">Datenschutzerklärung</Link>}
    </footer>
  )
}

export default Footer