import { Link } from 'gatsby'
import React from 'react'
import "../styles/header.scss"

const Header = () => {
  return (
    <div className='header'>
      <Link to="/">
        <svg className='logo' width="155" height="58" viewBox="0 0 155 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M128.764 23.9904L116.8 0.258707H106.239L94.238 24.0787L82.1044 0H71.5434L59.4098 24.0787L47.3643 0.170368H36.8032L25.0156 23.5613L13.2532 0.208228H0.0314504L0 0.227157L19.7383 39.3929H30.2993L42.0806 16.002L54.1324 39.9103H64.6872L76.827 15.8253L88.9543 39.9103H99.5153L111.523 16.0903L123.481 39.822H134.042L154 0.208228H140.741L128.764 23.9904Z" fill="black" />
          <path d="M58.6859 57.4543L56 50.1827H58.5063L59.7554 54.149L60.8576 50.1827H63.1761L64.2946 54.149L65.5274 50.1827H68.0582L65.3641 57.4543H63.4047L62.025 53.0929L60.6453 57.4543H58.6859Z" fill="black" />
          <path d="M103.368 51.6872L101.163 57.4543H100.624L97.9385 50.9502H98.6651L100.886 56.5349L103.025 50.9502H103.719L105.841 56.5349L108.086 50.9502H108.813L106.119 57.4543H105.572L103.368 51.6872Z" fill="black" />
          <path d="M139.665 54.2098L143.437 57.4543H142.498L138.8 54.3162V57.4543H138.122V46.8927H138.8V54.1035L142.498 50.9502H143.437L139.665 54.2098Z" fill="black" />
          <path d="M74.2308 50.1828H76.6799V57.4543H74.2308V50.1828ZM75.4635 46.3077C75.6748 46.2995 75.8857 46.3311 76.0834 46.4008C76.2812 46.4704 76.4618 46.5766 76.6144 46.7129C76.7669 46.8492 76.8882 47.0128 76.9711 47.1938C77.0539 47.3749 77.0965 47.5697 77.0963 47.7665C77.1093 48.0694 77.0248 48.3689 76.8534 48.6267C76.6821 48.8845 76.4319 49.0887 76.1349 49.2131C75.838 49.3375 75.5078 49.3764 75.1869 49.3248C74.866 49.2732 74.569 49.1335 74.3341 48.9236C74.0991 48.7137 73.937 48.4432 73.8685 48.1469C73.8001 47.8505 73.8284 47.5419 73.9498 47.2607C74.0712 46.9794 74.2802 46.7384 74.55 46.5686C74.8197 46.3988 75.1378 46.3079 75.4635 46.3077Z" fill="black" />
          <path d="M83.9785 57.4542V50.1827H86.4277V51.2389C86.688 50.8937 87.0258 50.6054 87.4178 50.3938C87.8097 50.1822 88.2463 50.0524 88.6974 50.0133C89.1486 49.9743 89.6034 50.027 90.0305 50.1678C90.4576 50.3085 90.8467 50.534 91.171 50.8285C91.6582 51.4973 91.8778 52.304 91.7914 53.108V57.4238H89.3422V53.7007C89.3422 52.6521 88.9994 51.9303 87.9625 51.9303C86.9257 51.9303 86.4604 52.6901 86.4604 53.7691V57.4238L83.9785 57.4542Z" fill="black" />
          <path d="M120.358 54.0959C120.356 53.5472 120.18 53.0111 119.852 52.5553C119.523 52.0995 119.058 51.7442 118.513 51.5343C117.968 51.3243 117.369 51.269 116.791 51.3753C116.212 51.4816 115.68 51.7448 115.262 52.1318C114.844 52.5187 114.559 53.0121 114.441 53.5499C114.324 54.0876 114.38 54.6457 114.603 55.1538C114.825 55.6618 115.205 56.0973 115.693 56.4052C116.181 56.7132 116.755 56.8799 117.345 56.8844C117.743 56.897 118.139 56.8337 118.51 56.6982C118.88 56.5627 119.217 56.358 119.499 56.0967C119.782 55.8354 120.004 55.5231 120.151 55.179C120.299 54.8349 120.369 54.4662 120.358 54.0959ZM113.712 54.0959C113.709 53.4192 113.922 52.7569 114.324 52.1932C114.727 51.6296 115.3 51.1899 115.971 50.9303C116.643 50.6707 117.382 50.6027 118.095 50.7351C118.808 50.8675 119.463 51.1943 119.975 51.6738C120.488 52.1534 120.837 52.7641 120.976 53.4283C121.115 54.0925 121.038 54.7802 120.756 55.4039C120.474 56.0276 120 56.5592 119.392 56.9311C118.785 57.303 118.072 57.4983 117.345 57.4923C116.864 57.5017 116.386 57.4201 115.939 57.2526C115.493 57.085 115.087 56.8349 114.748 56.5175C114.408 56.2 114.141 55.8217 113.963 55.4055C113.785 54.9892 113.7 54.5437 113.712 54.0959Z" fill="black" />
          <path d="M132.113 51.5505C131.887 51.4906 131.653 51.4624 131.419 51.4669C129.557 51.4669 128.741 53.0625 128.741 54.5062V57.4467H128.063V50.9502H128.741V52.7434C128.909 52.2012 129.26 51.7238 129.743 51.3817C130.225 51.0397 130.813 50.8511 131.419 50.8438C131.656 50.8349 131.892 50.8711 132.113 50.9502V51.5505Z" fill="black" />
          <path d="M154.49 55.6156C154.49 56.7401 153.519 57.4391 151.796 57.4391C150.245 57.4391 148.882 56.7553 148.882 55.0837H149.543C149.543 56.3982 150.58 56.8769 151.796 56.8769C153.013 56.8769 153.813 56.3982 153.813 55.6308C153.813 54.643 152.833 54.453 151.445 54.2099C150.057 53.9667 149.094 53.6476 149.094 52.4471C149.094 51.2466 150.131 50.6311 151.682 50.6311C153.233 50.6311 154.36 51.3909 154.36 52.789H153.699C153.699 51.7177 152.882 51.1934 151.666 51.1934C150.449 51.1934 149.755 51.7025 149.755 52.4471C149.755 53.3513 150.572 53.5336 151.976 53.7844C153.38 54.0351 154.474 54.2859 154.474 55.6156" fill="black" />
        </svg>
      </Link>
    </div>
  )
}

export default Header